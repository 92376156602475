"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Rating = _interopRequireDefault(require("../Rating"));
var _StickyBar = require("../StickyBar");
var _UnitStickyBar = _interopRequireDefault(require("../UnitStickyBar"));
var _BookButton = _interopRequireDefault(require("./BookButton"));
var _Nav = _interopRequireDefault(require("./Nav"));
var _TenantContext = require("../../context/TenantContext");
var __jsx = _react.default.createElement;
const BookingBar = ({
  data,
  openPlannerModal,
  booking,
  onBookingReservation,
  reserving,
  closePlannerModal,
  plannerModalIsOpen,
  loading
}) => {
  const {
    arrivalDate,
    departureDate,
    trip
  } = booking;
  const bookingIsValid = !!(arrivalDate && departureDate);
  const {
    type = 'unit',
    owner,
    rating
  } = data ?? {};
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  return __jsx(_UnitStickyBar.default, {
    booking: booking,
    openModal: openPlannerModal,
    plannerIsOpen: plannerModalIsOpen,
    loading: loading,
    confirmButton: __jsx(BookingButton, {
      size: "large",
      submitting: reserving,
      disabled: plannerModalIsOpen && !trip,
      onClick: trip ? onBookingReservation : openPlannerModal,
      "data-cy": "bookingButton"
    }, bookingIsValid && type === 'unit' && __jsx(_reactIntl.FormattedMessage, {
      id: "tHmTKn",
      defaultMessage: [{
        "type": 0,
        "value": "Reserveren"
      }]
    }), !bookingIsValid && type === 'unit' && __jsx(_reactIntl.FormattedMessage, {
      id: "G6c/hu",
      defaultMessage: [{
        "type": 0,
        "value": "Beschikbaarheid en prijzen"
      }]
    }), type === 'accommodation' && __jsx(_reactIntl.FormattedMessage, {
      id: "+YEsel",
      defaultMessage: [{
        "type": 0,
        "value": "Kies een verblijf"
      }]
    }))
  }, __jsx(_StickyBar.StickyBarThumnail, (0, _extends2.default)({
    className: "gt-l",
    subTitle: brandConfig.hasReviews && (rating ? __jsx(_Rating.default, {
      value: rating
    }) : undefined)
  }, data)), __jsx(_Nav.default, {
    delay: plannerModalIsOpen ? 500 : undefined,
    onClick: closePlannerModal,
    className: "gt-s",
    items: [{
      label: __jsx(_reactIntl.FormattedMessage, {
        id: "o3H3Tu",
        defaultMessage: [{
          "type": 0,
          "value": "Overzicht"
        }]
      }),
      name: 'intro'
    }, {
      label: __jsx(_reactIntl.FormattedMessage, {
        id: "0xAinF",
        defaultMessage: [{
          "type": 0,
          "value": "Ligging"
        }]
      }),
      name: 'location'
    }, ...(type === 'unit' ? [{
      label: __jsx(_reactIntl.FormattedMessage, {
        id: "bsmgox",
        defaultMessage: [{
          "type": 0,
          "value": "Extra's"
        }]
      }),
      name: 'extras',
      className: 'gt-m'
    }, {
      label: __jsx(_reactIntl.FormattedMessage, {
        id: "GIL6tw",
        defaultMessage: [{
          "type": 0,
          "value": "Prijs"
        }]
      }),
      name: 'price'
    }, ...(rating || owner ? [{
      label: rating ? __jsx(_reactIntl.FormattedMessage, {
        id: "Ed5Khw",
        defaultMessage: [{
          "type": 0,
          "value": "Beoordelingen"
        }]
      }) : __jsx(_reactIntl.FormattedMessage, {
        id: "ki4h34",
        defaultMessage: [{
          "type": 0,
          "value": "Eigenaar"
        }]
      }),
      name: `${rating ? 'reviews' : 'owner'}`,
      className: 'gt-m'
    }] : [])] : [])]
  }));
};
var _default = exports.default = BookingBar;
const BookingButton = (0, _styledComponents.default)(_BookButton.default).withConfig({
  displayName: "BookingBar__BookingButton",
  componentId: "eza3ug-0"
})(["margin-left:auto;flex-grow:1;"]);